import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/document.module.css'

export default function Documents(){
    return (
        <Layout>
            <section className={styles.document}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        The Documents that can have a Apostille are
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            A
                        </h3>
                        <p className="paragraph">
                            ACRO Police Certificate <br/>
                            ACPO Subject Access Report <br/>
                            Affidavit <br/>
                            Articles of Association
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            B
                        </h3>
                        <p className="paragraph">
                            Bank Statements <br/>
                            Baptism Certificate <br/>
                            Birth Certificate <br/>
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            C
                        </h3>
                        <p className="paragraph">
                            Certificate of Incorporation <br/>
                            Certificate of Memorandum <br/>
                            Certificate of Naturalisation <br/>
                            Certificate of No Impediment (CNI) <br/>
                            Chamber of Commerce Document <br/>
                            Change of Name Deed <br/>
                            Civil Partnership Certificate <br/>
                            Criminal Records Bureau (CRB) Document <br/>
                            Criminal Records Check <br/>
                            Companies House Document <br/>
                            County Court Document <br/>
                            Court Document <br/>
                            Court of Bankruptcy Document
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            D
                        </h3>
                        <p className="paragraph">
                            Death Certificate <br/>
                            Decree Nisi <br/>
                            Decree Absolute <br/>
                            Degree Certificate (UK) <br/>
                            Department of Business, Innovation and Skills (BIS) document <br/>                                                            
                            Department of Health document <br/>
                            Diploma <br/>
                            Disclosure Scotland <br/>
                            Doctor's Letter (Medical)
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            E
                        </h3>
                        <p className="paragraph">
                            Educational Certificate (UK) <br/>
                            Export Certificates
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            F
                        </h3>
                        <p className="paragraph">
                            Family Division of the High Court of Justice document <br/>
                            Finger Prints <br/>
                            Fit Note (Doctor)
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            G
                        </h3>
                        <p className="paragraph">
                            Government Issued Documents <br/>
                            Grant of Probate
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            H
                        </h3>
                        <p className="paragraph">
                            High Court of Justice Document <br/>
                            HM Revenue and Customs Document <br/>
                            Home Office Document
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            L
                        </h3>
                        <p className="paragraph">
                            Last Will and Testament <br/>
                            Letter from an Employer. Letter of invitation <br/>
                            Letter of Enrolment <br/>
                            Letter of No Trace
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            M
                        </h3>
                        <p className="paragraph">
                            Medical Report <br/>
                            Marriage Certificate
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            N
                        </h3>
                        <p className="paragraph">
                            Name Change Deed or Document
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            P
                        </h3>
                        <p className="paragraph">
                            Passport (copies only) <br/>
                            Pet Export Document from the Department of Environment, Food and Rural Affairs (DEFRA) <br/>      
                            Police Disclosure Document <br/>
                            Power of Attorney <br/>
                            Probate
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            R
                        </h3>
                        <p className="paragraph">
                            Reference from an Employer <br/>
                            Religious Documents
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            S
                        </h3>
                        <p className="paragraph">
                            Sheriff Court Document <br/>
                            Sick Note (Doctor) <br/>
                            Statutory Declaration
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            T
                        </h3>
                        <p className="paragraph">
                            Test Results (medical) <br/>
                            Translations done by BOSTICO INTERNATIONAL only
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            U
                        </h3>
                        <p className="paragraph">
                            Utility Bill
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            W
                        </h3>
                        <p className="paragraph">
                            Will
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            As a general rule of thumb we can legalised any document issued or published for public knowledge.such as court records, land deeds, and public registers) authenticated by a public officer or notary public or solicitor.
                        </p>
                        <p className="paragraph">
                            These documents are also called public record.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            If your document is not in the above list, do not worry, we can still help.
                        </p>
                        <p className="paragraph">
                            If you have a genuine UK document we can legalise it for you.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
